import Modal from 'react-bootstrap/Modal';


const Deleteuserconfirmation = ({show,setShow,handleDelete,sourceName='user' })=>{
  
  console.log('Deleteuserconfirmation 4444444444444444444444444444444444444444444444444444444444')
  const handleClose = () => setShow(false);
 

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      {console.log('modaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALLLLLLLLLLLLLLL')}
    <Modal.Header closeButton>
      <Modal.Title>Delete {sourceName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>Do you really want to delete this {sourceName}?</Modal.Body>
    <Modal.Footer>
    <button style={{height:"40px",width:"100px"}} className="btn-cancel" onClick={handleClose}>
          Cancel
        </button>
        <button  style={{height:"40px",width:"120px"}} className="btn-shiny2" onClick={handleDelete}>
          Delete 
        </button>
    </Modal.Footer>
  </Modal>
  );
}


export default Deleteuserconfirmation;