import React, { useState, useEffect } from "react";
import bootstrap from "bootstrap";
import UpdateRoleAndPermission from "../pages/UpdateRoleAndPermission";
// React Router
import { Link } from "react-router-dom";
// React Icons
import { TfiWrite } from "react-icons/tfi";
import { TiUserDelete } from "react-icons/ti";
// Import Toast
import Toast from "./Toast";
// Controller
import {
  getSingleUserPermission,
  deleteUserByAdmin,
} from "../controller/fetchApi";
import Deleteuserconfirmation from "./deleteuserconfirmation";

const CreatedUserTable = ({ tblHead, data, getAllUser, redirectLink ,setcurrdata}) => {
  // Toast Message Code
  const [showToast, setShowToast] = useState({ success: false, message: "" });

  // Token And Users Data
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  //open modal 
  const [showupdatamodal ,setshowupdatamodal] = useState(false);
  // Update Role And Permissions
  const [defaultValue, setDefaultValue] = useState();

  const [currentUser, setCurrentUser] = useState();

  const handleUpdateRoleAndPermission = async (user) => {
    //console.log("userValue from create table",user,"tokenId",tokenId)
    try {
      const roleAndPermissions = await getSingleUserPermission(
        user?.id,
        tokenId
      );
      console.log("roleAndPermissions",roleAndPermissions)
      setCurrentUser(user);
      setDefaultValue(roleAndPermissions);
    } catch (error) {
      console.log("Created User Update getModulePermissions :", error);
    }
  };
  //open Role and update modal
  const [updatemodal, setupdatemodal] = useState(false);
  //user id for deletion and confirmation for deletion
  const [deluser,setdeluser]=useState('');
  const [show,setshow] = useState(false)
  // Handle User Delete
  const handleUserDelete = async () => { console.log(deluser ,"user id for deletionnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
    // let assurance = confirm("Are you sure? You want to remove this user.")
    //if(assurance){
    try {
      const result = await deleteUserByAdmin(tokenId, deluser, setShowToast);
      if (result?.data?.status === 200) {
        getAllUser();
      }
    } catch (error) {
      console.log("user not deleted in superAdmin section 2", error);
    }finally{
      setshow(false)
    }
    //}
  };
  //user delete
  // const deleteuser = (userid)=>{
  //   let confirm = confirm("Are you sure? You want to delete this user.")
  //   if(confirm){
  //     handleUserDelete(user?.id)
  //   }
  // if(confirm("Are you sure? You want to delete this user.")){
  //   handleUserDelete(user?.id)
  // }
  //}

  // modal control through state
  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   if (showModal) {
  //     document.getElementById('updateRoleAndPermissionModal').style.display="block";
  //   } else {
  //     document.getElementById('updateRoleAndPermissionModal').style.display="none";
  //   }
  // }, [showModal]);
 
  return (
    <div className="LeadRightSectionTable_body table-responsive">
    
      <table className="table  ">
        <thead>
          <tr className="table-danger dashboard_section1_tableHead_tr">
            <th scope="col">{tblHead.firstHead}</th>
            <th scope="col" className="text-center">
              {tblHead.secondHead}
            </th>
            <th scope="col" className="text-center">
              {tblHead.thirdHead}
            </th>
            <th scope="col" className="text-center">
              {tblHead.seventhHead}
            </th>
            <th scope="col" className="text-center">
              {tblHead.fourthHead}
            </th>
            <th scope="col">{tblHead.fifthHead}</th>
            <th scope="col" className="text-center">
              {tblHead.sixthHead}
            </th>
            <th scope="col" className="text-center">
              {tblHead.eighthHead}
            </th>
            {/* <th scope="col">{tblHead.fifthHead}</th> */}
          </tr>
        </thead>
        <tbody className="dashboard_section1_tableBody ">
          {data && data?.length > 0 ? (
            data?.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className="text-center">{`${user?.firstName}  ${user?.lastName}`}</td>
                <td className="text-center">{user?.email}</td>
                <td className="text-center">{user?.role || "Not Assigned"}</td>
                <td className="text-center">{user?.mobile}</td>
                <td>{user?.id}</td>
                <td className="text-center">
                  {/* hhhhhhhhhh */}
                  <Link
                    to={redirectLink}
                    className="Link-button-leads"
                    state={{ userData: user }}
                  >
                    <TfiWrite
                      className="fs-4"
                      style={{ color: "rgba(145, 155, 250, 1)" }}
                    />
                  </Link>
                  {/* hhhhhhhhhhhhh */}
                  &nbsp; &nbsp;
                  <Link
                    className="Link-button-leads"
                    data-bs-toggle="modal"
                    data-bs-target="#updateRoleAndPermissionModal"
                  > 
                  {/* <TfiWrite
                  className="fs-4"
                  style={{ color: "rgba(145, 155, 250, 1)", marginRight:'15px'}}
                /> */}
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => { 
                        handleUpdateRoleAndPermission(user);
                       // setshowupdatamodal(true)
                      }}
                    >
                      Update
                    </button>
                    {/* <BsPencilSquare
                      className="fs-4"
                      style={{ color: "rgba(145, 155, 250, 1)" }}
                      onClick={() => {
                        handleUpdateRoleAndPermission(user);
                      }}
                    /> */}
                  </Link>
                </td>
                <td className="text-center">
                  <TiUserDelete
                    className="fs-3 created_user_table_action_delete"
                    //  onClick={deleteuser}
                    onClick={() =>{ 
                      //handleUserDelete(user?.id)
                      setshow(true)
                      setdeluser(user?.id)
                    } }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No users at this time</td>
            </tr>
          )}
        </tbody>
      </table>


      {/* Update Role And Permission Modal */}
      {
       <>   
        <div 
          className="modal modal_bar fade modal-xl "
          id="updateRoleAndPermissionModal"
          
         data-bs-backdrop="static"
         data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
         
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header w-100">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              {console.log(setupdatemodal,'setupdatemodalllllllll')}
              <div className="modal-body">
                <UpdateRoleAndPermission 
                showupdatamodal={setshowupdatamodal}
                setcurrdata = {setcurrdata}
                  setUpmodal={setupdatemodal}
                  currentUser={currentUser}
                  defaultValue={defaultValue}
                   
                />
              </div>
             
            </div>
          </div>
        </div>
      </>}
{/*      
     {showupdatamodal && (  // Only render the modal if showupdatamodal is true
    <div 
        className="modal fade show" 
        style={{ display: 'block' }} // Show the modal with block display
        id="updateRoleAndPermissionModal"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Update Role and Permission</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setshowupdatamodal(false)} // Close modal on button click
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body">
                    <UpdateRoleAndPermission 
                        setcurrdata={setcurrdata}
                        currentUser={currentUser}
                        defaultValue={defaultValue}
                    />
                </div>
            </div>
        </div>
    </div>
)} */}



      {
        show&&<Deleteuserconfirmation
        show={show}
        setShow={setshow}
        handleDelete={handleUserDelete}
        deleteId={deluser}
        sourceName="User"
      />
      }  
      
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreatedUserTable;
